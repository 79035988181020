import React from 'react';

import { a } from 'react-spring';

import { useIntersect } from 'hooks/useIntersect';
import { useAnimateInTrail, IAnimateInProps } from 'hooks/useAnimateInTrail';
import { initialOffset } from 'components/Animations/SpringProperties/SpringProperties';

import SimpleLink from 'components/Links/SimpleLink/SimpleLink';

type IOwnProps = IAnimateInProps;

type IProps = IOwnProps;

const NotFoundHero: React.FC<IProps> = ({ animationReady, ...props }) => {
  const [inViewRef, inView] = useIntersect({
    startObserving: animationReady,
  });

  const animateInSpring = useAnimateInTrail(inView, 3);

  const titleStyles = {
    opacity: animateInSpring[0].active,
    transform: animateInSpring[0].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const messageStyles = {
    opacity: animateInSpring[1].active,
    transform: animateInSpring[1].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const ctaStyles = {
    opacity: animateInSpring[2].active,
    transform: animateInSpring[2].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  return (
    <section ref={inViewRef} className='notFoundHeroWrapper'>
      <div className='pageMessageWrapper'>
        <div className='messageWrapper'>
          <a.h1 style={titleStyles}>
            <strong>404.</strong>&nbsp;Oops, you found a page that doesn’t exist.
          </a.h1>
          <a.p style={messageStyles}>
            Don’t worry, we’ve probably messed up. Just to be sure, would you mind checking you spelt the URL correctly? Regardless, you may as well have a look around our homepage.
          </a.p>
        </div>
      </div>
      <div className='returnCtaWrapper'>
        <a.h1 style={ctaStyles}>
          <SimpleLink to='/' size='medium' title='Home'>
            Take me home.
          </SimpleLink>
        </a.h1>
      </div>
    </section>
  );
};

export default NotFoundHero;
