import React from 'react';

import { a } from 'react-spring';

import { useIntersect } from 'hooks/useIntersect';
import { useAnimateInTrail, IAnimateInProps } from 'hooks/useAnimateInTrail';
import { initialOffset } from 'components/Animations/SpringProperties/SpringProperties';
import { InterfaceWrapper } from 'components/Layout/InterfaceWrapper/InterfaceWrapper';

type IOwnProps = IAnimateInProps;

type IProps = IOwnProps;

const UseDesktopHero: React.FC<IProps> = ({ animationReady, ...props }) => {
  const [inViewRef, inView] = useIntersect({
    startObserving: animationReady,
  });

  const animateInSpring = useAnimateInTrail(inView, 3);

  const logoStyles = {
    opacity: animateInSpring[0].active,
    transform: animateInSpring[0].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const titleStyles = {
    opacity: animateInSpring[1].active,
    transform: animateInSpring[1].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const messageStyles = {
    opacity: animateInSpring[2].active,
    transform: animateInSpring[2].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  return (
    <InterfaceWrapper animationReady={animationReady} onlyChatModal>
      <section ref={inViewRef} className='useDesktopHeroWrapper'>
        <div>
          <div className='logoContainer'>
            <a.div className='logo' style={logoStyles} />
          </div>
          <div className='pageMessageWrapper'>
            <div className='messageWrapper'>
              <a.h1 style={titleStyles}>
                <strong>We know.</strong>&nbsp;Our mobile site should really be finished.
            </a.h1>
              <a.p style={messageStyles}>
                We're currently working on it, like literally, we're working on it right now. In the meantime, you wouldn't mind having a look around our site on a desktop pc too much would you? We think you'll love it!
            </a.p>
            </div>
          </div>
        </div>
      </section>
    </InterfaceWrapper>
  );
};

export default UseDesktopHero;
