import React, { useState, useRef, useEffect, Dispatch } from 'react';
import { useLatest } from 'utils/Index';

import { useSelector } from 'react-redux';

import * as fromViewport from 'store/Viewport';

export const useIntersect = ({
  root = null,
  rootMargin = "-300px 0px -300px 0px",
  threshold = 0,
  startObserving = false,
  fireOnEveryEntry = false,
}) => {
  const [node, setNode] = useState(null);

  const [inView, setInView] = useState(false);
  const [currentlyInView, setCurrentlyInView] = useState(false);

  const viewportEntranceOffsetScale = useSelector(fromViewport.getEntranceOffsetScale);

  const getScaledRootMargins = (margins: string, scale: number): string => {
    let newRootMargin = "";
    margins.replace("px", "").split(" ").map((margin, index) => {
      const space = (index === 0 ? "" : " ");
      const newMargin = parseInt(margin, 10) * scale;
      newRootMargin = newRootMargin + space + newMargin.toString() + "px";
    });
    return newRootMargin;
  };

  const [scaledRootMargin, setScaledRootMargin] = useState(getScaledRootMargins(rootMargin, viewportEntranceOffsetScale));

  useEffect(() => {
    //console.log('updating the rootmargins', rootMargin);
    const newRootMargin = getScaledRootMargins(rootMargin, viewportEntranceOffsetScale);
    setScaledRootMargin(newRootMargin);
  }, [rootMargin, viewportEntranceOffsetScale]);

  const latestStartObserving = useLatest(startObserving);
  const latestFireOnEveryEntry = useLatest(fireOnEveryEntry);
  const latestInView = useLatest(inView);
  const latestCurrentlyInView = useLatest(currentlyInView);

  const observer = useRef(null);

  const updateState = entry => {
    // console.log(latestFireOnEveryEntry.current, latestInView.current, latestCurrentlyInView.current, entry.intersectionRatio);
    if (latestStartObserving.current) {
      //console.log('we are observing');
      if (entry.intersectionRatio > 0) {
        if (!latestCurrentlyInView.current && latestFireOnEveryEntry.current)
          setCurrentlyInView(true);
        if (!latestInView.current)
          setInView(true);
      } else {
        if (latestCurrentlyInView.current && latestFireOnEveryEntry.current)
          setCurrentlyInView(false);
      }
    }
  };

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    if (startObserving && ((!inView && !fireOnEveryEntry) || (fireOnEveryEntry))) {
      observer.current = new IntersectionObserver(
        ([entry]) => updateState(entry),
        {
          root,
          rootMargin: scaledRootMargin,
          threshold,
        },
      );
    }

    const { current: currentObserver } = observer;

    if (node && startObserving && ((!inView && !fireOnEveryEntry) || (fireOnEveryEntry))) currentObserver.observe(node);

    return () => currentObserver ? currentObserver.disconnect() : null;
  }, [node, startObserving, root, scaledRootMargin, threshold, inView, fireOnEveryEntry]);

  useEffect(() => {
    if (inView && !fireOnEveryEntry) {
      const { current: currentObserver } = observer;
      currentObserver.disconnect();
    }
  }, [node, inView, fireOnEveryEntry]);

  const returnValues: [Dispatch<any>, boolean, boolean] = [setNode, inView, currentlyInView];

  return returnValues;
};

//const useIntersect = ({ root = null, rootMargin = "0px", threshold = 0, startObserving = false, fireOnEveryEntry = false }) => {
//  const [node, setNode] = useState(null);

//  const [inView, setInView] = useState(false);
//  const [currentlyInView, setCurrentlyInView] = useState(false);

//  const latestStartObserving = useLatest(startObserving);
//  const latestFireOnEveryEntry = useLatest(fireOnEveryEntry);
//  const latestInView = useLatest(inView);
//  const latestCurrentlyInView = useLatest(setCurrentlyInView);

//  const observer = useRef(null);

//  const updateState = (entry) => {
//    if (latestStartObserving.current) {
//      //console.log('we are observing');
//      if (entry.intersectionRatio > 0) {
//        if (!latestCurrentlyInView.current && latestFireOnEveryEntry.current)
//          setCurrentlyInView(true);
//        if (!latestInView.current)
//          setInView(true);
//      } else {
//        if (latestCurrentlyInView.current && latestFireOnEveryEntry.current)
//          setCurrentlyInView(false);
//      }
//    }
//  }

//  useEffect(() => {
//    if (startObserving) {
//      if (observer.current) observer.current.disconnect();

//      observer.current = new IntersectionObserver(
//        ([entry]) => updateState(entry),
//        {
//          root,
//          rootMargin,
//          threshold
//        }
//      );
//    }

//    const { current: currentObserver } = observer;

//    if (node && startObserving) currentObserver.observe(node);

//    return () => currentObserver ? currentObserver.disconnect() : null;
//  }, [node, startObserving, root, rootMargin, threshold]);

//  let returnValues: [Dispatch<any>, boolean, boolean];
//  returnValues = [setNode, inView, currentlyInView]

//  return returnValues;
//};

//const useVisibility = (Cref: RefObject<HTMLDivElement>, startObserving: boolean) => {
//  const [initialised, setInitialised] = useState(false);
//  const [componentInView, setComponentInView] = useState(false);
//  const [componentCurrentlyInView, setComponentCurrentlyInView] = useState(false);

//  const latestStartObserving = useLatest(startObserving);
//  const latestComponentInView = useLatest(componentInView);
//  const latestComponentCurrentlyInView = useLatest(componentCurrentlyInView);

//  useEffect(() => {
//    if (!initialised) {
//      console.log('setting up the observer');
//      const observer = new IntersectionObserver(
//        ([entry]) => {
//          if (latestStartObserving.current) {
//            console.log('we are observing');
//            if (entry.intersectionRatio > 0) {
//              if (!latestComponentCurrentlyInView.current)
//                setComponentCurrentlyInView(true);
//              if (!latestComponentInView.current)
//                setComponentInView(true);
//            } else {
//              if (latestComponentCurrentlyInView.current)
//                setComponentCurrentlyInView(false);
//            }
//          }
//        },
//        {
//          root: null,
//          rootMargin: '0px',
//          threshold: 0,
//        }
//      )

//      if (Cref.current) {
//        observer.observe(Cref.current);
//        setInitialised(true);
//      }
//    }
//  }, [startObserving, initialised]);

//  return { componentInView, componentCurrentlyInView };
//}

//export const useComponentInView = (componentReady: boolean = false, rootMargin: string = "-300px 0px -300px 0px") => {
//  const [componentInView, setComponentInView] = useState(false);
//  //const [componentCurrentlyInView, setComponentCurrentlyInView] = useState(false);
//  const viewportEntranceOffsetScale = useSelector(fromViewport.getEntranceOffsetScale);

//  const getScaledRootMargins = (margins: string, scale: number): string => {
//    let newRootMargin = "";
//    margins.replace("px", "").split(" ").map((margin, index) => {
//      const space = (index === 0 ? "" : " ");
//      const newMargin = parseInt(margin) * scale;
//      newRootMargin = newRootMargin + space + newMargin.toString() + "px";
//    });
//    return newRootMargin;
//  }

//  const [scaledRootMargin, setScaledRootMargin] = useState(getScaledRootMargins(rootMargin, viewportEntranceOffsetScale));

//  useEffect(() => {
//    const newRootMargin = getScaledRootMargins(rootMargin, viewportEntranceOffsetScale);
//    setScaledRootMargin(newRootMargin);
//  }, [rootMargin, viewportEntranceOffsetScale]);

//  const [componentRef, inView, entry] = useInView({
//    /* Optional options */
//    rootMargin: scaledRootMargin,
//    threshold: 0,
//  });

//  const [delay, setDelay] = useState(10);
//  const [markComponentAsInViewInterval, setMarkComponentAsInViewInterval] = useState(false);

//  const markComponentAsInView = () => {
//    if (!componentInView) {
//      setComponentInView(true);
//      //console.log('marking component as in view');
//    }
//  }

//  useInterval(() => {
//    if (componentReady) {
//      setMarkComponentAsInViewInterval(false);
//      markComponentAsInView();
//    }
//  }, markComponentAsInViewInterval ? delay : null);

//  useEffect(() => {
//    //if (componentCurrentlyInView !== inView)
//    //  setComponentCurrentlyInView(inView);
//    if (inView && !componentInView) {
//      if (componentReady) {
//        markComponentAsInView();
//      } else {
//        setMarkComponentAsInViewInterval(true);
//      }
//    } else {
//      setMarkComponentAsInViewInterval(false);
//    }
//  }, [inView, componentInView, componentReady]);
//  //}, [inView, componentInView, componentCurrentlyInView, componentReady]);
//  //console.log('rerendering useComponentInView');

//  return {
//    componentRef,
//    //componentCurrentlyInView,
//    componentInView
//  }; // Convert this into returning an array as i think the object is rerendering multiple times, maybe assign a type definition to the array on return?
//}
