import React from 'react';
import { connect } from 'react-redux';

import { a } from 'react-spring';

import { IApplicationState } from 'store/index';
import * as fromMainNavigation from 'store/MainNavigation';

import { useIntersect } from 'hooks/useIntersect';
import { useAnimateInTrail, IAnimateInProps } from 'hooks/useAnimateInTrail';
import { initialOffset } from 'components/Animations/SpringProperties/SpringProperties';
import { InterfaceWrapper } from 'components/Layout/InterfaceWrapper/InterfaceWrapper';

import SimpleLink from 'components/Links/SimpleLink/SimpleLink';

type IOwnProps = IAnimateInProps;

interface IStateProps {
  navItems: fromMainNavigation.INavItem[];
}

type IDispatchProps = typeof fromMainNavigation.actionCreators;

type IProps = IStateProps & IDispatchProps & IOwnProps;

const Footer: React.FC<IProps> = ({ animationReady, navItems, ...props }) => {
  const [inViewRef, inView] = useIntersect({
    startObserving: animationReady,
  });

  const animateInTrail = useAnimateInTrail(inView, 5);

  const mainNavigationItems = navItems.map((navItem, index) => (
    <li key={index}>
      <SimpleLink to={navItem.url} id={index.toString()} size='medium' title={navItem.title}>
        {navItem.title}
      </SimpleLink>
    </li>
  ));

  const emblemStyles = {
    opacity: animateInTrail[0].active,
    transform: animateInTrail[0].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const missionStyles = {
    opacity: animateInTrail[1].active,
    transform: animateInTrail[1].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const separatorStyles = {
    opacity: animateInTrail[2].active,
    transform: animateInTrail[2].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const separatorLineStyles = {
    width: animateInTrail[2].active.to(x => `calc(${x} * 100%)`),
  };

  const copyrightStyles = {
    opacity: animateInTrail[4].active,
    transform: animateInTrail[4].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const navStyles = {
    opacity: animateInTrail[3].active,
    transform: animateInTrail[3].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const socialStyles = {
    opacity: animateInTrail[4].active,
    transform: animateInTrail[4].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  return (
    <InterfaceWrapper animationReady={animationReady}>
      <section ref={inViewRef} className='footerWrapper'>
        <div className='companySection'>
          <a.div
            className='companyEmblem'
            style={emblemStyles}
          />
          <a.p
            className='companyMission'
            style={missionStyles}
          >
            Make life’s experiences extraordinary
          </a.p>
        </div>
        <a.div
          className='separator'
          style={separatorStyles}
        >
          <a.div
            className='line'
            style={separatorLineStyles}
          />
        </a.div>
        <div className='flex-container navigationSection'>
          <a.div
            className='copyrightStatement'
            style={copyrightStyles}
          >
            &copy; {(new Date()).getFullYear()} Qore Studio.
          {/*&copy; {(new Date()).getFullYear()} Qore Studio. All rights reserved.*/}
          </a.div>
          <div className='flex-child siteNavigation'>
            <a.ul
              className='footer-nav-list'
              style={navStyles}
            >
              {mainNavigationItems}
            </a.ul>
          </div>
          <a.div
            className='socialLinks'
            style={socialStyles}
          >
            All rights reserved.
          {/*<SimpleLink to='https://www.facebook.com' type='classic' newWindow size='medium' title='Facebook'>
            Facebook
          </SimpleLink>*/}
          </a.div>
        </div>
      </section>
    </InterfaceWrapper>
  );
};

// Selects which state properties are merged into the component's props
const mapStateToProps = (state: IApplicationState, ownProps: IOwnProps): IStateProps => ({
  navItems: fromMainNavigation.getMainNavItems(state),
});

// Selects which action creators are merged into the component's props
const mapDispatchToProps: IDispatchProps = fromMainNavigation.actionCreators;

// Wire up the React component to the Redux store
export default connect<IStateProps, IDispatchProps, IOwnProps>
  (mapStateToProps, mapDispatchToProps)(Footer);
