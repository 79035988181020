import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { SEOHelmet } from 'components/SEO/SEOHelmet';

import classNames from 'classnames';

import Footer from 'components/Footer/Footer/Footer';
import UseDesktopHero from 'components/Heros/UseDesktopHero/UseDesktopHero';

import * as fromUserInterface from 'store/UserInterface';

import { useSpring, a, SpringConfig } from 'react-spring';
import { defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

import { usePrevious, hashCode } from 'utils/Index';

export const usePageWrapper = (fullScreen = false) => {
  const history = useHistory();
  const location = useLocation();

  const selectLoadingScreen = useMemo(
    fromUserInterface.makeGetLoadingScreen,
    [],
  );
  const [screenId] = useState(hashCode(location.pathname).toString());
  const [initialised, setInitialised] = useState(false);

  const loadingScreen = useSelector(state =>
    selectLoadingScreen(state, screenId),
  );

  const dispatch = useDispatch();
  const changeLoadingScreenLogomarkBackground = useCallback(
    (url: string) => dispatch(fromUserInterface.actionCreators.changeLoadingScreenLogomarkBackground(screenId, url))
    , [dispatch]);

  const previousAnimatePageIn = usePrevious(loadingScreen.animatePageIn);

  useEffect(() => {
    if (loadingScreen.animatePageIn && !loadingScreen.visible && !previousAnimatePageIn && !initialised && previousAnimatePageIn !== undefined) {
      const action = history.action;
      setInitialised(true);

      if (action === 'PUSH' || action === 'POP' || action === 'REPLACE')
        window.scrollTo(0, 0);
    }
  }, [loadingScreen.animatePageIn, history, initialised]);

  const footerComponent = () => (!fullScreen ? <Footer animationReady={loadingScreen.animatePageContentIn} /> : null);

  const opacitySpringConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 85,
    friction: 29,
  };
  const [animateInOpacitySpring, setAnimateInOpacitySpring] = useSpring(() => ({ opacity: 0, config: opacitySpringConfig }));

  useEffect(() => {
    setAnimateInOpacitySpring({ opacity: loadingScreen.animatePageIn ? 1 : 0 });
  }, [loadingScreen.animatePageIn]);

  const pageWrapperStyles = {
    opacity: animateInOpacitySpring.opacity,
  };

  const PageWrapperComponent = (children: JSX.Element) => (
    <a.div className='pageContainer' style={pageWrapperStyles}>
      <div
        className={classNames(
          'pageWrapper',
          fullScreen,
          'mobile',
        )}
      >
        <SEOHelmet
          schema="WebPage"
          title="Our mobile site should really be finished"
          description="Our mobile site should really be finished"
          contentType="product"
        />
        <UseDesktopHero animationReady={loadingScreen.animatePageContentIn} />
      </div>
      <div
        className={classNames(
          'pageWrapper',
          { fullScreen },
          'desktop',
        )}
      >
        {children}
        {footerComponent()}
      </div>
    </a.div>
  );

  let returnValues: [(children: JSX.Element) => JSX.Element, boolean, (url: string) => void, boolean];
  returnValues = [PageWrapperComponent, loadingScreen.animatePageContentIn, changeLoadingScreenLogomarkBackground, loadingScreen.animatePageIn];

  return returnValues;
};
