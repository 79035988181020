import React from 'react';

import { useIntersect } from 'hooks/useIntersect';

import classNames from 'classnames';

import InterfaceOverlay from 'components/InterfaceOverlay/InterfaceOverlay';

export type IBackgroundShade = 'light' | 'dark';

interface IProps {
  animationReady?: boolean;
  coverAnotherOverlay?: boolean;
  backgroundShade?: IBackgroundShade;
  noOverlay?: boolean;
  onlyChatModal?: boolean;
}

const InterfaceWrapperComponent: React.FC<IProps> = ({
  animationReady = false,
  coverAnotherOverlay = false,
  backgroundShade = 'dark',
  noOverlay = false,
  onlyChatModal = false,
  ...props
}) => {

  const [inViewRef, inView, currentlyInView] = useIntersect({
    startObserving: animationReady,
    rootMargin: "300px 0px 300px 0px",
    fireOnEveryEntry: true,
  });

  const interfaceOverlay = () => (currentlyInView && !noOverlay ? <InterfaceOverlay backgroundShade={backgroundShade} mainSiteOverlay={false} onlyChatModal={onlyChatModal} /> : null);

  return (
    <div
      ref={inViewRef}
      className={classNames(
        'interfaceWrapper',
        { coverAnotherOverlay },
      )}
    >
      {props.children}
      {interfaceOverlay()}
    </div>
  );
};

export const InterfaceWrapper = InterfaceWrapperComponent;
